const zh_CN = {
  LABEL_CONNECT_WALLET: "连接钱包",
  LABEL_RESOURCE: "更多资料",
  LABEL_COMMUNITY: "社交媒体",
  LABEL_CONTACT: "联系我们",
  LP_LIST_TITLE: "代币交易对",
  LP_TRADING_PAIR: "交易对",
  LP_SYX_PRICE: "价格",
  LP_MY_SHARE: "我的份额",
  LP_MY_DEPOSIT: "质押数量",
  LP_DEPOSIT: "质押",
  LP_WITHDRAW: "赎回",
  LP_DEPOSIT_WITHDRAW_REWARD: "确定",
  LP_SWAP: "交易",
  RP_LIST_TITLE: "奖励池",
  RP_TYPE: "名称",
  RP_MY_REWARDS: "我的奖励",
  RP_MY_STAKES: "已抵押",
  RP_STAKE: "质押",
  RP_UNSTAKE: "赎回",
  RP_WITHDRAW_REWARDS: "提取奖励",
  POPUP_TITLE_DEPOSIT: "存入交易池",
  POPUP_TITLE_WITHDRAW: "取出交易池",
  POPUP_WALLET_BALANCE: "可用余额",
  POPUP_DEPOSITABLE_AMOUNT: "可存入金额",
  POPUP_WITHDRAWABLE_AMOUNT: "可取出金额",
  POPUP_TRADEABLE_AMOUNT: "可交易金额",
  POPUP_INPUT_AMOUNT: "金额",
  POPUP_INPUT_MAX: "全部",
  POPUP_INPUT_TOKEN: "选择代币",
  POPUP_DEPOSIT_RESULT: "存入后我的份额（估算）：",
  POPUP_WITHDRAW_RESULT: "取出后余额（估算）：",
  POPUP_ACTION_DEPOSIT: "质押",
  POPUP_ACTION_WITHDRAW: "赎回",
  POPUP_TITLE_STAKE: "抵押到奖金池",
  POPUP_TITLE_UNSTAKE: "取出资产",
  POPUP_STAKE_BALANCE: "当前抵押数",
  POPUP_AVAILABLE_STAKE: "可抵押余额",
  POPUP_TITLE_SWAP: "交易",
  POPUP_LABEL_FROM: "卖出",
  POPUP_LABEL_TO: "买入 (估算)",
  POPUP_LABEL_SWAP_RATE: "1 {tokenFrom} 兑换 {rate} {tokenTo}",
  POPUP_ACTION_CONFIRM: "确定",
  POPUP_STAKE_NEW_BALANCE: "新抵押数 (预估)",
  TOTAL_UNSTAKED_SHARE: "未抵押份额",
  MY_STAKING_APR: "我的年化收益",
  AVG_STAKING_APR: "平均年化",
  TOTAL_REWARDS: "已获得奖励",
  WITHDRAWABLE_REWARDS: "可提取奖励",
  END_TIME: "结束时间",
  WALLET_BALANCE: "钱包余额",
  INVESTABLE_AMOUNT: "可投资金额",
  DEPOSIT_INCENTIVE_PLAN: "存入奖励池",
  RATIO: "比例",
  ACTION: "操作",
  STAKING_TIP: "将 VLX 存入奖励池即可获得 SYX 收益",
  WITHDRAW_REWARDS_TIP: "取出奖励后，可在钱包内查看已获得的奖励代币 SYX",
  UNIT_PRICE: "单价",
  HOME_TITLE: "连接现实资产到区块链的桥梁",
  HOME_SUBTITLE: "Symblox是一个区块链合成资产发行和交易协议",
  JOIN: "加入奖励池",
  TOTAL_SUPPLY: "总量",
  HOLD: "持有",
  TOTAL_STAKE_AFTER_DEPOSIT: "存入后总金额",
  TOTAL_STAKE: "已存入奖励池",
  TRADE_ERROR_BALANCE: "可用余额不足",
  SEE_DETAIL: "查看详情",
  REWARD_DISTRIBUTION_RATIO: "奖励分配比例",
  NETWORK_ERROR: "当前网络不支持，请切换到 {requiredNetwork} 使用（可在钱包设置网络节点RPC地址为：{rpcUrl})",
  POPUP_WITHDRAW_AMOUNT: "取出抵押资产",
  POPUP_WITHDRAW_REWARD: "取出奖励",
  RISK_WARNING: "该项目合约正在审计中，请在使用之前查看合约具体内容并了解需要承担的风险",
  COMMUNITY: "社区",
  CONTRACT: "合约",
  SYX_TOKEN: "SYX",
  SYX_TOKEN_V2: "SYX (V2)",
  SYX_TOKEN_V1: "SYX (V1)",
  USDT_TOKEN: "USDT (VELAS)",
  WETH_TOKEN: "WETH (VELAS)",
  WVLX_TOKEN: "VLX 托管",
  DEV_FUND: "开发基金",
  REFERRER: "推荐人地址",
  WALLET_CONNECT_TO: "已连接网络 {network}",
  WALLET_DISCONNECT: "取消连接",
  EXCHANGE: "兑换",
  EXCHANGE_TITLE: "兑换新的 SYX 代币参与 Symblox 最新流动性挖矿",
  EXCHANGE_SUB_TITLE: "您想兑换多少个新的 SYX 代币？",
  EXCHANGE_TIP: "输入您想兑换的金额",
  EXCHANGE_RATE: "SYX 兑换比例",
  SVLX_EXCHANGE_RATE: "SVLX 兑换 VLX 比例",
  VLX_EXCHANGE_RATE: "VLX 兑换 SVLX 比例",
  EXCHANGE_TOTAL_SUPPLY: "SYX 可兑换总数",
  EXCHANGE_WALLET_BALANCE: "可兑换余额: {amount}",
  EXCHANGE_WALLET_BALANCE2: "可兑换余额: {amount} ｜ {amount2}",
  EXCHANGE_WARNING: "如果设置过《 SYX 投票委托》，请勿使用以上兑换功能，以免造成你的 SYX 代币损失！",
  DAPP_MINING_V1: "V1 矿池",
  DAPP_MINING_V2: "V2 矿池",
  REDEEM_SYX: "SYX 兑换",
  DAPP_CROSS_CHAIN: "跨链",
  DAPP_PVLX: "pVLX",
  SWAP_SVLX: "sVLX",
  V1: "V1",
  LANGUAGE: "语言",
  SLIPPAGE_TOLERANCE: "价格滑点保护",
  MIN_RECEIVED: "最低收到",
  WITHDRAWABLE_TIP:
    "可直接取出金额为{maxWithdrawable}, 已预约金额{orderedAmount}, 剩余可在申请后{stakingEpochDuration}分钟后取出",
  WITHDRAW_APPLY: "预约取现",
  EXPECTED_TO_GET: "预计将获得",
  DEPOSIT: "质押",
  WITHDRAW: "赎回",
  SVLX_WITHDRAW_ERR: "当前 VELAS 节点暂时锁定，请过30分钟以后再试",
  EXCHANGEABLE_AMOUNT_NOT_ENOUGH: "可兑换金额不足"
};
export default zh_CN;
